import * as Checkbox from "@radix-ui/react-checkbox";
import * as RadioGroup from "@radix-ui/react-radio-group";
import React from "react";
import { cn } from "../utils";
import { Toggle, type ToggleProps } from "./Toggle";

type BaseToggleCardProps = Omit<ToggleProps, "asChild" | "className"> & {
  className?: string;
  icons?: React.ReactNode[];
  contentClassName?: string;
  value?: string;
  text?: string;
  responseType: "single-choice" | "multiple-choice";
};

type ToggleCardPropsWithTitle = BaseToggleCardProps & {
  title: string;
  children?: never;
};

type ToggleCardPropsWithChildren = BaseToggleCardProps & {
  title?: never;
  children: React.ReactNode;
};

type ToggleCardProps = ToggleCardPropsWithTitle | ToggleCardPropsWithChildren;

export const ToggleCardInput = React.forwardRef<
  HTMLButtonElement,
  ToggleCardProps
>(
  (
    {
      title,
      value,
      text,
      responseType,
      pressed,
      icons = [],
      className,
      children,
      contentClassName,
      onPressedChange,
      ...props
    },
    ref,
  ) => {
    const toggleClasses = cn(
      "h-auto w-[162px]",
      "flex flex-col items-center justify-center gap-2 p-6",
      "transition-colors",
      "border-system-opacity-10 rounded-lg border-2",
      "shadow-[0px_2px_2px_0px_rgba(0,0,0,0.1)]",
      "focus-visible:outline-none focus-visible:ring-2",
      "data-[state=checked]:border-brand-primary-dark data-[state=checked]:bg-brand-moss-greens-10",
      className,
    );

    return responseType === "multiple-choice" ? (
      <Checkbox.Root
        ref={ref}
        {...props}
        value={value ?? ""}
        asChild
        checked={pressed}
        onCheckedChange={onPressedChange}
      >
        <Toggle role="presentation" className={toggleClasses}>
          {icons.map((icon, index) => (
            <div
              key={index}
              className="text-brand-secondary-dark [&_svg]:h-5 [&_svg]:w-5"
            >
              {icon}
            </div>
          ))}
          <div
            className={cn(
              "text-brand-secondary-dark text-center text-sm font-medium",
              contentClassName,
            )}
          >
            {title ?? children}
          </div>
        </Toggle>
      </Checkbox.Root>
    ) : (
      <RadioGroup.Item ref={ref} {...props} value={value ?? ""} asChild>
        <Toggle role="presentation" pressed={pressed} className={toggleClasses}>
          {icons.map((icon, index) => (
            <div
              key={index}
              className="text-brand-secondary-dark [&_svg]:h-5 [&_svg]:w-5"
            >
              {icon}
            </div>
          ))}
          <div
            className={cn(
              "text-brand-secondary-dark text-center text-sm font-medium",
              contentClassName,
            )}
          >
            {title ?? children}
          </div>
        </Toggle>
      </RadioGroup.Item>
    );
  },
);

ToggleCardInput.displayName = "ToggleCardInput";
