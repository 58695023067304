import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from "contentful";

export interface TypeCtaFields {
  title?: EntryFieldTypes.Symbol;
  description?: EntryFieldTypes.Symbol;
  successMessage?: EntryFieldTypes.Symbol;
  formName?: EntryFieldTypes.Symbol;
  buttonText?: EntryFieldTypes.Symbol;
  submittingButtonText?: EntryFieldTypes.Symbol;
  url?: EntryFieldTypes.Symbol;
  variant: EntryFieldTypes.Symbol<
    "default" | "ghost" | "outline" | "secondary" | "zest"
  >;
  iconName?: EntryFieldTypes.Symbol;
  challengerURL?: EntryFieldTypes.Symbol;
  disclaimerText?: EntryFieldTypes.Text;
}

export type TypeCtaSkeleton = EntrySkeletonType<TypeCtaFields, "cta">;
export type TypeCta<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeCtaSkeleton, Modifiers, Locales>;

export function isTypeCta<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeCta<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "cta";
}
