"use client";

import { Button } from "./Button";
import { cn } from "../utils";
import { differenceInCalendarDays, format } from "date-fns";
import * as React from "react";
import { DayPicker, useDayPicker, type DayPickerProps } from "react-day-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { H4 } from "./typography/H4";
import { Spinner } from "./Spinner";

export type AvailabilitySlots = Record<
  /** Date string in "yyyy-mm-dd" format */
  string,
  {
    /** Start time of the availability slot */
    startTime: Date;
    /** End time of the availability slot */
    endTime: Date;
  }[]
>;

type CalendarProps = DayPickerProps & {
  /**
   * In the year view, the number of years to display at once.
   * @default 12
   */
  yearRange?: number;
  /**
   * Whether to show the year switcher in the caption.
   * @default true
   */
  showYearSwitcher?: boolean;
  monthsClassName?: string;
  monthCaptionClassName?: string;
  weekdaysClassName?: string;
  weekdayClassName?: string;
  monthClassName?: string;
  captionClassName?: string;
  captionLabelClassName?: string;
  buttonNextClassName?: string;
  buttonPreviousClassName?: string;
  navClassName?: string;
  monthGridClassName?: string;
  weekClassName?: string;
  dayClassName?: string;
  dayButtonClassName?: string;
  rangeStartClassName?: string;
  rangeEndClassName?: string;
  selectedClassName?: string;
  todayClassName?: string;
  outsideClassName?: string;
  disabledClassName?: string;
  rangeMiddleClassName?: string;
  hiddenClassName?: string;
  showAvailability?: boolean;
  availabilitySlots?: AvailabilitySlots;
  hasAvailabilityClassName?: string;
  noAvailabilityClassName?: string;
  modifiers?: Record<string, boolean>;
  modifiersClassNames?: Record<string, string>;
  onDayClick?: (
    day: Date,
    modifiers: Record<string, boolean>,
    e: React.MouseEvent,
  ) => void;
  onTimeSelect?: (startTime: Date, endTime: Date) => void;
  processingTimeSelection?: boolean;
};

function Calendar({
  className,
  showOutsideDays = true,
  showYearSwitcher = true,
  yearRange = 12,
  numberOfMonths,
  showAvailability = false,
  availabilitySlots,
  onTimeSelect,
  processingTimeSelection = false,
  ...props
}: CalendarProps) {
  const [navView, setNavView] = React.useState<"days" | "years">("days");
  const [displayYears, setDisplayYears] = React.useState<{
    from: number;
    to: number;
  }>(
    React.useMemo(() => {
      const currentYear = new Date().getFullYear();
      return {
        from: currentYear - Math.floor(yearRange / 2 - 1),
        to: currentYear + Math.ceil(yearRange / 2),
      };
    }, [yearRange]),
  );

  const { onNextClick, onPrevClick, startMonth, endMonth } = props;
  const columnsDisplayed = navView === "years" ? 1 : numberOfMonths;

  // Class names with tailwind defaults
  const _monthsClassName = cn("relative flex", props.monthsClassName);
  const _monthCaptionClassName = cn(
    "relative mx-10 flex h-7 items-center justify-center",
    props.monthCaptionClassName,
  );
  const _weekdaysClassName = cn("flex flex-row", props.weekdaysClassName);
  const _weekdayClassName = cn(
    "w-8 text-sm font-normal text-muted-foreground",
    props.weekdayClassName,
  );
  const _monthClassName = cn("w-full", props.monthClassName);
  const _captionClassName = cn(
    "relative flex items-center justify-center pt-1",
    props.captionClassName,
  );
  const _captionLabelClassName = cn(
    "truncate text-sm font-medium",
    props.captionLabelClassName,
  );
  const buttonNavClassName = cn(
    "absolute h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
  );
  const _buttonNextClassName = cn(
    buttonNavClassName,
    "right-0",
    props.buttonNextClassName,
  );
  const _buttonPreviousClassName = cn(
    buttonNavClassName,
    "left-0",
    props.buttonPreviousClassName,
  );
  const _navClassName = cn("flex items-start", props.navClassName);
  const _monthGridClassName = cn("mx-auto mt-4", props.monthGridClassName);
  const _weekClassName = cn("mt-2 flex w-max items-start", props.weekClassName);
  const _dayClassName = cn(
    "flex size-8 flex-1 items-center justify-center p-0 text-sm",
    props.dayClassName,
  );
  const _dayButtonClassName = cn(
    "size-8 rounded-md p-0 font-normal hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
    props.dayButtonClassName,
  );

  const buttonRangeClassName =
    "bg-accent [&>button]:bg-primary [&>button]:text-primary-foreground [&>button]:hover:bg-primary [&>button]:hover:text-primary-foreground";

  const _rangeStartClassName = cn(
    buttonRangeClassName,
    "day-range-start rounded-s-md",
    props.rangeStartClassName,
  );
  const _rangeEndClassName = cn(
    buttonRangeClassName,
    "day-range-end rounded-e-md",
    props.rangeEndClassName,
  );
  const _rangeMiddleClassName = cn(
    "bg-accent !text-foreground [&>button]:bg-transparent [&>button]:!text-foreground [&>button]:hover:bg-transparent [&>button]:hover:!text-foreground",
    props.rangeMiddleClassName,
  );
  const _selectedClassName = cn(
    "[&>button]:bg-brand-primary-regular [&>button]:text-white [&>button]:font-semibold [&>button]:hover:bg-brand-primary-dark",
    props.selectedClassName,
  );
  const _todayClassName = cn(
    "[&>button]:bg-primary/10 [&>button]:border-2 [&>button]:border-brand-primary-dark [&>button]:font-semibold",
    props.todayClassName,
  );
  const _outsideClassName = cn(
    "text-muted-foreground opacity-50",
    props.outsideClassName,
  );
  const _disabledClassName = cn(
    "text-muted-foreground opacity-50",
    props.disabledClassName,
  );
  const _hiddenClassName = cn("invisible flex-1", props.hiddenClassName);

  const _hasAvailabilityClassName = cn(
    "[&>button]:bg-brand-tertiary-20 [&>button]:font-medium",
    props.hasAvailabilityClassName,
  );

  const _noAvailabilityClassName = cn(
    "[&>button]:bg-system-detrimental-light [&>button]:font-medium",
    props.noAvailabilityClassName,
  );

  const modifiers = React.useMemo(() => {
    if (!showAvailability || !availabilitySlots) return props.modifiers;

    return {
      ...props.modifiers,
      hasAvailability: (date: Date) => {
        // Don't show availability for past dates
        if (date < new Date(new Date().setHours(0, 0, 0, 0))) {
          return false;
        }

        const dateStr = date.toISOString().split("T")[0]; // Convert to YYYY-MM-DD
        return dateStr in availabilitySlots;
      },
      noAvailability: (date: Date) => {
        // Don't show unavailability styling for past dates
        if (date < new Date(new Date().setHours(0, 0, 0, 0))) {
          return false;
        }

        const dateStr = date.toISOString().split("T")[0]; // Convert to YYYY-MM-DD
        return !(dateStr in availabilitySlots);
      },
      disabled: (date: Date): boolean => {
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        if (date < today) {
          return true;
        }
        const dateStr = date.toISOString().split("T")[0];
        return !(dateStr in availabilitySlots);
      },
    };
  }, [showAvailability, availabilitySlots, props.modifiers]);

  const [showTimePicker, setShowTimePicker] = React.useState(false);

  const handleDayClick = (
    day: Date,
    modifiers: Record<string, boolean>,
    e: React.MouseEvent,
  ) => {
    // Don't do anything if clicking the already selected date
    // This is to prevent weird behavior when showing times, todo: assess
    if (modifiers.selected) {
      return;
    }

    if (modifiers.hasAvailability) {
      setShowTimePicker(true);
    }
    props.onDayClick?.(day, modifiers, e);
  };

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      style={{
        width: 248.8 * (columnsDisplayed ?? 1) + "px",
      }}
      modifiers={modifiers}
      modifiersClassNames={{
        ...props.modifiersClassNames,
        hasAvailability: _hasAvailabilityClassName,
        noAvailability: _noAvailabilityClassName,
      }}
      classNames={{
        months: _monthsClassName,
        month_caption: _monthCaptionClassName,
        weekdays: _weekdaysClassName,
        weekday: _weekdayClassName,
        month: _monthClassName,
        caption: _captionClassName,
        caption_label: _captionLabelClassName,
        button_next: _buttonNextClassName,
        button_previous: _buttonPreviousClassName,
        nav: _navClassName,
        month_grid: _monthGridClassName,
        week: _weekClassName,
        day: _dayClassName,
        day_button: _dayButtonClassName,
        range_start: _rangeStartClassName,
        range_middle: _rangeMiddleClassName,
        range_end: _rangeEndClassName,
        selected: _selectedClassName,
        today: _todayClassName,
        outside: _outsideClassName,
        disabled: _disabledClassName,
        hidden: _hiddenClassName,
      }}
      components={{
        Chevron: ({ orientation }) => {
          return (
            <FontAwesomeIcon
              icon={orientation === "left" ? faChevronLeft : faChevronRight}
              className="h-4 w-4 text-gray-600"
            />
          );
        },
        Nav: ({ className }) => {
          const { nextMonth, previousMonth, goToMonth } = useDayPicker();

          const isPreviousDisabled = (() => {
            if (navView === "years") {
              return (
                (startMonth &&
                  differenceInCalendarDays(
                    new Date(displayYears.from - 1, 0, 1),
                    startMonth,
                  ) < 0) ||
                (endMonth &&
                  differenceInCalendarDays(
                    new Date(displayYears.from - 1, 0, 1),
                    endMonth,
                  ) > 0)
              );
            }
            return !previousMonth;
          })();

          const isNextDisabled = (() => {
            if (navView === "years") {
              return (
                (startMonth &&
                  differenceInCalendarDays(
                    new Date(displayYears.to + 1, 0, 1),
                    startMonth,
                  ) < 0) ||
                (endMonth &&
                  differenceInCalendarDays(
                    new Date(displayYears.to + 1, 0, 1),
                    endMonth,
                  ) > 0)
              );
            }
            return !nextMonth;
          })();

          const handlePreviousClick = () => {
            if (!previousMonth) return;
            setShowTimePicker(false);
            if (navView === "years") {
              setDisplayYears((prev) => ({
                from: prev.from - (prev.to - prev.from + 1),
                to: prev.to - (prev.to - prev.from + 1),
              }));
              onPrevClick?.(
                new Date(
                  displayYears.from - (displayYears.to - displayYears.from),
                  0,
                  1,
                ),
              );
              return;
            }
            if (!showTimePicker) {
              goToMonth(previousMonth);
            }
            onPrevClick?.(previousMonth);
          };

          const handleNextClick = () => {
            if (!nextMonth) return;
            setShowTimePicker(false);
            if (navView === "years") {
              setDisplayYears((prev) => ({
                from: prev.from + (prev.to - prev.from + 1),
                to: prev.to + (prev.to - prev.from + 1),
              }));
              onNextClick?.(
                new Date(
                  displayYears.from + (displayYears.to - displayYears.from),
                  0,
                  1,
                ),
              );
              return;
            }
            goToMonth(nextMonth);
            onNextClick?.(nextMonth);
          };

          return (
            <nav className={cn("flex items-center", className)}>
              <Button
                className="absolute left-0 h-7 w-7 bg-transparent p-0 opacity-80 hover:opacity-100"
                disabled={isPreviousDisabled}
                onClick={handlePreviousClick}
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="h-4 w-4 text-gray-600"
                />
              </Button>
              {!showTimePicker && (
                <Button
                  className="absolute right-0 h-7 w-7 bg-transparent p-0 opacity-80 hover:opacity-100"
                  disabled={isNextDisabled}
                  onClick={handleNextClick}
                >
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="h-4 w-4 text-gray-600"
                  />
                </Button>
              )}
            </nav>
          );
        },
        CaptionLabel: ({ children, ...props }) => {
          if (!showYearSwitcher) return <span {...props}>{children}</span>;
          return (
            <Button
              className="h-7 w-full truncate text-sm font-medium"
              variant="ghost"
              onClick={() => {
                setShowTimePicker(false);
                setNavView((prev) => (prev === "days" ? "years" : "days"));
              }}
            >
              {navView === "days"
                ? children
                : displayYears.from + " - " + displayYears.to}
            </Button>
          );
        },
        MonthGrid: ({ className, children, ...props }) => {
          const { goToMonth, selected } = useDayPicker();

          if (navView === "years") {
            return (
              <div
                className={cn("grid grid-cols-4 gap-y-2", className)}
                {...props}
              >
                {Array.from(
                  { length: displayYears.to - displayYears.from + 1 },
                  (_, i) => {
                    const isBefore =
                      startMonth &&
                      differenceInCalendarDays(
                        new Date(displayYears.from + i, 11, 31),
                        startMonth,
                      ) < 0;
                    const isAfter =
                      endMonth &&
                      differenceInCalendarDays(
                        new Date(displayYears.from + i, 0, 0),
                        endMonth,
                      ) > 0;
                    const isDisabled = isBefore || isAfter;

                    return (
                      <Button
                        key={i}
                        className={cn(
                          "h-7 w-full text-sm font-normal",
                          displayYears.from + i === new Date().getFullYear() &&
                            "bg-accent text-accent-foreground font-medium",
                        )}
                        variant="ghost"
                        onClick={() => {
                          setNavView("days");
                          goToMonth(
                            new Date(
                              displayYears.from + i,
                              (selected as Date | undefined)?.getMonth() ?? 0,
                            ),
                          );
                        }}
                        disabled={isDisabled}
                      >
                        {displayYears.from + i}
                      </Button>
                    );
                  },
                )}
              </div>
            );
          }

          if (showTimePicker) {
            const dateStr = (selected as Date | undefined)
              ?.toISOString()
              ?.split("T")?.[0];

            if (!dateStr)
              return (
                <div className={className} {...props}>
                  <p>
                    No availability for this date. Please pick another date.
                  </p>
                </div>
              );

            const slots = availabilitySlots?.[dateStr] || [];

            return (
              <div
                className={cn(
                  "flex flex-col items-center space-y-2",
                  className,
                )}
                {...props}
              >
                <H4 className="text-brand-primary-regular">
                  {format(selected as unknown as Date, "EEEE, MMM do")}
                </H4>
                {processingTimeSelection ? (
                  <Spinner />
                ) : (
                  <>
                    {slots.map((slot) => (
                      <Button
                        key={slot.startTime.toISOString()}
                        onClick={() => {
                          onTimeSelect?.(slot.startTime, slot.endTime);
                        }}
                        className="w-full"
                      >
                        {format(slot.startTime, "h:mm a")} -{" "}
                        {format(slot.endTime, "h:mm a")}
                      </Button>
                    ))}
                  </>
                )}
              </div>
            );
          }

          return (
            <table className={className} {...props}>
              {children}
            </table>
          );
        },
      }}
      numberOfMonths={columnsDisplayed}
      onDayClick={handleDayClick}
      {...props}
    />
  );
}

Calendar.displayName = "Calendar";

export { Calendar };
